// import simpleParallax from 'simple-parallax-js';

// Form
// import './form/form'


// Hamburger
//import './hamburger/hamburger'
//import './modal/modal'

import './px/main-fix'