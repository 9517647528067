import Swiper, { Navigation, Thumbs, Pagination, EffectFade, Autoplay } from "swiper";
Swiper.use([Thumbs, Navigation, Pagination, EffectFade, Autoplay ]);

if(document.querySelector('.-welcome-new-js')) {
  const welcomeSwiper = new Swiper(".-welcome-new-js", {
    slidesPerView: 1,
    effect: "fade",
    fadeEffect: {
      crossFade: true,
    },
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
    },
  });
}

if(document.querySelector('.-ourworks-slider-js')) {
  const welcomeSwiper = new Swiper(".-ourworks-slider-js", {
    slidesPerView: 1,
    effect: "fade",
    fadeEffect: {
      crossFade: true,
    },
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
    },
    autoplay: {
      delay: 3000,
    },
    navigation: {
      nextEl: '.swiper-button-prev-js',
      prevEl: '.swiper-button-next-js',
    },
  });
}

if(document.querySelector('.-actually-slider-js')) {
  const actuallySwiper = new Swiper(".-actually-slider-js", {
    slidesPerView: 1,
    spaceBetween: 5,
    centeredSlides: true,
    centeredSlidesBounds: true,

    breakpoints: {
      576: {
        slidesPerView: 2,
        spaceBetween: 24,
      },
      768: {
        slidesPerView: 3,
        spaceBetween: 24,
      },
      1024: {
        centeredSlides: false,
        centeredSlidesBounds: false,
        slidesPerView: 4,
        spaceBetween: 24,
      },
    },
    navigation: {
      nextEl: '.swiper-button-prev-js',
      prevEl: '.swiper-button-next-js',
    },
  });
}

// $(".js-range-slider").ionRangeSlider();

// let currencySelect = document.getElementById('currency-select');
// let updateSelected = function(e) {
//   document.querySelector('.is-visible').classList.remove('is-visible');
//   document.querySelector('[data-currency-code="' + e.target.value + '"]').classList.add('is-visible');
// };
// currencySelect && currencySelect.addEventListener('change', updateSelected);

// if(document.querySelector('.stone__small-slider-js')) {
//   const stoneDetailSmallSwiper = new Swiper(".stone__small-slider-js", {
//     slidesPerView: 'auto',
//     freeMode: true,
//     watchSlidesProgress: true,
//     direction: 'horisontal',
//     breakpoints: {
//         992: {
//             direction: 'vertical'
//         },
//     }
//   });
//   const stoneDetailBigSwiper = new Swiper(".stone__big-slider-js", {
//       spaceBetween: 10,
//       slidesPerView: 1,
//       thumbs: {
//         swiper: stoneDetailSmallSwiper,
//       },
//       navigation: {
//         nextEl: '.swiper-button-next-js',
//         prevEl: '.swiper-button-prev-js',
//       },
//   });
// }

// const tableHeader = document.querySelector('.catalog__header-js');
// tableHeader && tableHeader.addEventListener('click', (e) => {
//   const curr = e.target;
//   const active = tableHeader.querySelector('._active');
//   if (active) {
//     active.classList.remove('_active');
//   }
//   if(active != curr) {
//     curr.classList.add('_active');
//   }
// })
